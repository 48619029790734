<template>
    <div
        class="d-flex flex-row align-items-center justify-content-center"
        style="min-height: 100vh"
    >
        <div>
            <b-card
                no-body
                class="border border-2 p-5"
                style="min-width: 500px; max-width: 500px"
            >
                <b-form>
                    <div class="mb-5">
                        <h3 class="text-center mb-2">
                            Reestablecer contraseña
                        </h3>
                        <div class="text-justify">
                            Por favor ingrese los datos solicitados a
                            continuación para reestablecer su contraseña
                        </div>
                    </div>
                    <div v-if="!showCodeInput">
                        <b-form-group>
                            <label for="username">Usuario</label>
                            <b-form-input
                                id="username"
                                type="text"
                                v-model="$v.form.username.$model"
                                :state="validateState('username')"
                                :disabled="showCodeInput"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="!$v.form.username.minLength"
                            >
                                Este campo debe tener mínimo
                                {{ $v.form.username.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <!-- @keydown.enter="onLogin()()" -->
                            <b-form-invalid-feedback
                                v-if="!$v.form.username.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group>
                            <!-- {{ $v.form.tipo_documento_id }} -->
                            <label for="tipo_documento_id"
                                >Tipo documento
                                <!-- <small style="color: red">*</small> -->
                            </label>
                            <b-form-select
                                id="tipo_documento_id"
                                :options="tipos_documento"
                                text-field="descripcion"
                                value-field="id"
                                :disabled="showCodeInput"
                                v-model="$v.form.tipo_documento_id.$model"
                                :state="validateState('tipo_documento_id')"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Seleccione</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-form-invalid-feedback
                                v-if="!$v.form.tipo_documento_id.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group class="mt-4">
                            <label for="n_documento">Número de documento</label>
                            <b-form-input
                                id="n_documento"
                                type="number"
                                v-model="$v.form.n_documento.$model"
                                :state="validateState('n_documento')"
                                :disabled="showCodeInput"
                            ></b-form-input>
                            <!-- @keydown.enter="onLogin()()" -->
                            <b-form-invalid-feedback
                                v-if="!$v.form.n_documento.minLength"
                            >
                                Este campo debe tener mínimo
                                {{ $v.form.n_documento.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.form.n_documento.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button
                            v-if="!showCodeInput"
                            variant="primary"
                            block
                            :disabled="isLoading"
                            size="md"
                            class="mt-5"
                            @click="generateCode"
                        >
                            <span
                                v-if="isLoading"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <b-spinner small></b-spinner>
                                <span class="ml-2">Generando código...</span>
                            </span>
                            <span v-else>Generar código</span>
                        </b-button>
                    </div>

                    <div v-if="showCodeInput">
                        <div class="text-justify my-4">
                            Hemos enviado un código de verificación a la
                            dirección de correo asociada a este usuario
                        </div>
                        <b-form-group class="mt-4">
                            <label for="n_documento"
                                >Código de verificación</label
                            >
                            <b-form-input
                                id="code"
                                type="text"
                                v-model="$v.form.code.$model"
                                :state="validateState('code')"
                                :disabled="createdPassword"
                            ></b-form-input>
                            <!-- @keydown.enter="onLogin()()" -->
                            <b-form-invalid-feedback
                                v-if="!$v.form.code.minLength"
                            >
                                Este campo debe tener mínimo
                                {{ $v.form.code.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.form.code.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <div class="text-justify my-4">
                            Por favor ingrese su nueva contraseña a continuación
                        </div>
                        <b-form-group>
                            <label for="username">Nueva contrseña</label>
                            <b-form-input
                                id="newPwd"
                                type="text"
                                v-model="$v.form.newPwd.$model"
                                :state="validateState('newPwd')"
                                :disabled="createdPassword"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="!$v.form.newPwd.minLength"
                            >
                                Este campo debe tener mínimo
                                {{ $v.form.newPwd.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <!-- @keydown.enter="onLogin()()" -->
                            <b-form-invalid-feedback
                                v-if="!$v.form.newPwd.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group>
                            <label for="username"
                                >Confirmar nueva contrseña</label
                            >
                            <b-form-input
                                id="confirmPwd"
                                type="text"
                                v-model="$v.form.confirmPwd.$model"
                                :state="validateState('confirmPwd')"
                                :disabled="createdPassword"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="!$v.form.confirmPwd.minLength"
                            >
                                Este campo debe tener mínimo
                                {{ $v.form.confirmPwd.$params.minLength.min }}
                                caracteres
                            </b-form-invalid-feedback>
                            <!-- @keydown.enter="onLogin()()" -->
                            <b-form-invalid-feedback
                                v-if="!$v.form.confirmPwd.equalToNew"
                            >
                                Las contraseñas no coinciden
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback
                                v-if="!$v.form.confirmPwd.required"
                            >
                                Campo requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- v-if="!createdPassword" -->
                        <b-button
                            variant="primary"
                            block
                            :disabled="isLoading || createdPassword"
                            size="md"
                            class="mt-5"
                            @click="createNewPassword"
                        >
                            <span
                                v-if="isLoading"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <b-spinner small></b-spinner>
                                <span class="ml-2"
                                    >Reestableciendo contraseña...</span
                                >
                            </span>
                            <span v-else>Reestablecer contraseña</span>
                        </b-button>
                    </div>

                    <div
                        :class="`mt-4 text-center ${
                            isHovered ? 'text-primary' : ''
                        }`"
                        v-b-hover="hovered"
                        role="button"
                        @click="$router.push('/auth/login')"
                    >
                        Iniciar sesión
                    </div>
                </b-form>
            </b-card>
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import services from '@/boot/axios'

const { API } = services

export default {
    name: 'OlvidoSuContrasena',
    mixins: [validationMixin],
    data: () => ({
        form: {
            username: '',
            n_documento: '',
            tipo_documento_id: null,
            code: '',
            newPwd: '',
            confirmPwd: ''
        },
        tipos_documento: [],
        showCodeInput: false,
        createdPassword: false,
        isLoading: false,
        isHovered: false
    }),
    validations: {
        form: {
            username: {
                required,
                minLength: minLength(4)
            },
            tipo_documento_id: {
                required
            },
            n_documento: {
                required,
                minLength: minLength(4)
            },
            code: {
                required,
                minLength: minLength(4)
            },
            newPwd: {
                required,
                minLength: minLength(8)
            },
            confirmPwd: {
                required,
                minLength: minLength(8),
                equalToNew: function () {
                    const { confirmPwd, newPwd } = this.form
                    return confirmPwd === newPwd
                }
            }
        }
    },
    mounted() {
        this.getTiposDocumento()
    },
    methods: {
        hovered(value) {
            this.isHovered = value
        },
        generateCode() {
            const valid =
                !this.$v.form.tipo_documento_id.$invalid &&
                !this.$v.form.username.$invalid &&
                !this.$v.form.n_documento.$invalid
            if (valid) {
                this.isLoading = true
                const {
                    n_documento: numero_doc,
                    username,
                    tipo_documento_id: tipo_doc_id
                } = this.form
                // console.log('Gnerating code...')
                API.POST({
                    url: 'auth/recordarpwd/gencode',
                    data: {
                        p_datajson: {
                            username,
                            numero_doc,
                            tipo_doc_id
                        },
                        p_opc: 'CHANGE_PWD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        // console.log(response.data);
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.showCodeInput = true
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.form.username.$touch()
                this.$v.form.n_documento.$touch()
                this.$v.form.tipo_documento_id.$touch()
            }
        },
        createNewPassword() {
            const valid =
                !this.$v.form.code.$invalid &&
                !this.$v.form.newPwd.$invalid &&
                !this.$v.form.confirmPwd.$invalid
            if (valid) {
                this.isLoading = true
                const {
                    n_documento: numero_doc,
                    username,
                    tipo_documento_id: tipo_doc_id,
                    code: codigo,
                    confirmPwd: password
                } = this.form
                // console.log('Reestableciendo contraseña...')
                API.POST({
                    url: 'auth/recordarpwd/changepwd',
                    data: {
                        p_datajson: {
                            username,
                            numero_doc,
                            tipo_doc_id,
                            codigo,
                            password
                        },
                        p_opc: 'CHANGE_PWD',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        // console.log(response.data);
                        const {
                            data: {
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.createdPassword = true
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Contraseña actualizada con exito!',
                                    {
                                        title: 'Exito!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'success',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'success',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then((value) => {
                                    // console.log(value);
                                    if (value) {
                                        this.$router.push('/auth/login')
                                    }
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            } else {
                this.$v.form.newPwd.$touch()
                this.$v.form.confirmPwd.$touch()
                this.$v.form.code.$touch()
            }
        },
        getTiposDocumento() {
            API.POST({
                url: 'general/query',
                data: {
                    p_datajson: {},
                    p_opc: 'GET_ALL_TIPO_DOCUMENTO',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    // console.log(response.data)
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.tipos_documento = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        }
    }
}
</script>
